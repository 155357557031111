import { Container, FormSubmit, Loading } from 'components';
import { navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { ClientInformation, DistributorUserInfo } from 'modules/cart';
import { useProfileHook } from 'modules/profil';
import { ApplicationState } from 'modules/redux-store';
import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { cart } from 'styles';

const UserInfo = lazy(() => import('modules/cart/components/UserInfo'));
const DeliveryInfo = lazy(() => import('modules/cart/components/DeliveryInfo'));
const BusinessInfo = lazy(() => import('modules/cart/components/BusinessInfo'));

const Profile: React.FC = () => {
  const {
    onSubmit,
    user,
    isValidPassword,
    arePasswordsTheSame,
    newPasswordEmpty,
  } = useProfileHook();
  const { formatMessage } = useIntl();

  const methods = useForm<ClientInformation>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const authUser = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    if (!authUser.isLoggedIn) navigate('/');
  }, [authUser]);

  const pageLayout =
    authUser.user && !authUser.user.emailVerified
      ? cart.page
      : cart.pageNoEmailVerificationMessage;

  return (
    <Container css={pageLayout}>
      <Helmet title={'Profil - Wienerberger Webshop'} />
      <h2 css={[cart.pageTitle, cart.distributorHeader]}>
        <FormattedMessage id="profile.title" />
      </h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {authUser.user?.role === 'distributor' ? (
            <DistributorUserInfo logo={user?.distributorLogo} user={user} />
          ) : authUser.user?.role === 'customer' ||
            authUser.user?.role === 'admin' ||
            authUser.user?.role === 'sales' ? (
            <Suspense fallback={<Loading isLoading />}>
              <UserInfo
                isPhoneRequired={false}
                userInfoData={user?.userInfoData}
                showChangePassword={true}
                isValidPassword={isValidPassword}
                arePasswordsTheSame={arePasswordsTheSame}
                isNewPasswordEmpty={newPasswordEmpty}
              />
              <DeliveryInfo
                shouldValidate={false}
                showSpecialRemark={false}
                deliveryData={user?.deliveryData}
              />
              <BusinessInfo
                showSaveData={false}
                isOptional={false}
                bussinesData={user?.bussiness}
              />
            </Suspense>
          ) : null}
          <section css={cart.infoSection}>
            <div />
            <div css={cart.infoCta}>
              <FormSubmit text={formatMessage({ id: 'profile.form.submit' })} />
            </div>
          </section>
        </form>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </Container>
  );
};

export default Profile;
