import { ClientData } from 'models';
import { ClientInformation } from 'modules/cart';
import { AuthService } from 'modules/firebase';
import { SuccessToast } from 'modules/inbox';
import { ApplicationState } from 'modules/redux-store';
import { useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const authService = AuthService();

export const useProfileHook = () => {
  const { isLoggedIn } = useSelector((state: ApplicationState) => state.auth);
  const [user, setUser] = useState<ClientData>();
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [arePasswordsTheSame, setArePasswordsTheSame] =
    useState<boolean>(false);
  const [newPasswordEmpty, setNewPasswordEmpty] = useState<boolean>(false);

  async function getUserData() {
    const data = await authService.getUserData();

    setUser({
      userInfoData: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        phone: data?.phone,
      },
      deliveryData: {
        address: data?.address,
        location: data?.location,
        postCode: data?.postCode,
        city: data?.city,
        country: data?.country,
        specialRemark: data?.specialRemark,
      },
      bussiness: data?.business,
      distributorLogo: {
        logoFull: data?.logoFull,
        logoCircle: data?.logoCircle,
      },
    });
  }

  useEffect(() => {
    getUserData();
  }, [isLoggedIn]);

  async function onSubmit(data: ClientInformation) {
    if (isValidPassword) setIsValidPassword(false);
    if (arePasswordsTheSame) setArePasswordsTheSame(false);
    if (newPasswordEmpty) setNewPasswordEmpty(false);

    if (data.oldPassword) {
      if (!data.newPassword) {
        setNewPasswordEmpty(true);
        toast.error(
          <SuccessToast title=" " text="Molimo unesite novu lozinku." />,
        );
        return;
      } else {
        if (data.oldPassword === data.newPassword) {
          setArePasswordsTheSame(true);
          toast.error(
            <SuccessToast
              title=" "
              text="Nova i stara lozinka ne mogu biti iste"
            />,
          );
          return;
        }
        const isValidPassword = await authService.changePassword(
          data.oldPassword,
          data.newPassword,
        );
        if (!isValidPassword) {
          setIsValidPassword(true);
          toast.error(<SuccessToast title=" " text="Neispravna lozinka" />);
          return;
        } else {
          authService.setUserData(data);
          toast.success(
            <SuccessToast
              title=" "
              text="Uspješno ste promijenili lozinku i podatke"
            />,
          );
          return;
        }
      }
    } else {
      authService.setUserData(data);
      toast.success(
        <SuccessToast title=" " text="Uspješno ste promijenili podatke" />,
      );
      return;
    }
  }

  return {
    onSubmit,
    user,
    isValidPassword,
    arePasswordsTheSame,
    newPasswordEmpty,
  };
};
